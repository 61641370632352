import { Routes, Route, useLocation } from 'react-router-dom';
//import { useHistory } from "react-router-dom";
import Layout from './Layout';

// Routes
import Home from '../routes/Home';
import About from '../routes/About';
import ProfileEntity from '../routes/ProfileEntity';
//import ProfileFarm from '../routes/ProfileFarm';
//import ProfileBrand from '../routes/ProfileBrand';
import ProfileMemberIndividual from '../routes/ProfileMemberIndividual';
import ProfileMemberOrganization from '../routes/ProfileMemberOrganization';
//import SearchMember from '../routes/SearchMember';
import SearchPublic from '../routes/SearchPublic';
import CommunityList from '../routes/CommunityListPublic';
import InfoPublic from '../routes/InfoPublic';
//import InfoMember from '../routes/InfoMember';
import Visuals from '../routes/Visuals';
import Map from '../routes/Map';
import Definitions from '../routes/Definitions';
import Blog from '../routes/Blog';

// Test Routes (Embed)
import Dummy from '../routes/Dummy';
import EmbedAWS from '../routes/EmbedAWS';
import EmbedLocal from '../routes/EmbedLocal';

import GeoMap from '../routes/GeoMap'; // Experimental
import MapBox from '../routes/MapBox'; // Experimental
//import SandBox from '../routes/Sandbox'; // Experimental

//import ProductSearch from '../routes/ProductSearch';
//import ProductSearchLoggedIn from '../routes/ProductSearchLoggedIn';
//import ProductSearchLoggedOut from '../routes/ProductSearchLoggedOut';
//import ProductSearch3 from '../routes/ProductSearch3';

//import Sandbox from '../routes/Sandbox';

// Functions

// Below are additions specific to AppBar
// Imports from React
import * as React from 'react';
import { Link } from 'react-router-dom';

// For Google Analytics
//import React, { useEffect } from "react";
import ReactGA from "react-ga4";

// Imports from MUI
import {Grid, Box, Container, Stack} from '@mui/material'; 
//import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import {Button as ButtonMUI} from '@mui/material';
import Divider from '@mui/material/Divider';
import CssBaseline from '@mui/material/CssBaseline';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import { styled } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import MenuIcon from '@mui/icons-material/Menu';
import Button from '@mui/material/Button';
import AccountCircle from '@mui/icons-material/AccountCircle';
import Menu from '@mui/material/Menu';

// Imports from Amplify
//import { Auth } from 'aws-amplify';
//import { Flex, withAuthenticator } from '@aws-amplify/ui-react';
import "@aws-amplify/ui-react/styles.css";
import { Authenticator, Heading, Image, Text, useAuthenticator, useTheme, View } from "@aws-amplify/ui-react";

// To find page names ... need a function to take 'path' an return actual Name.
//import MenuItems from './MenuItems';
ReactGA.initialize("G-S15ZWDNBJW");

const App = () => {

  // #####################################
  // KEY DUMMY WEBSITE VARIABLE TO SWITCH OFF WEBSITE IF NEEDED
  const appOverride = true; // If 'true' only a dummy (flat) webpage is show vs the full App. Normally this is 'false'!
// appOverride set to true on Oct 8, 2024 to Decommission website (soft first step).  


  // Page-level in/out controls here it controls the 'navigation menu' links to pages
  const enableGroup1 = true; // Controls whether this group of pages is active (true) or not (false).
  const enableGroup2 = true; // Controls whether this group of pages is active (true) or not (false).
  const enableGroup3 = true; // Controls whether this group of pages is active (true) or not (false).
  // Group1 = Product Search + Producer Profiles (i.e. core items)
  // Group2 = Member Profiles for Indiviuals and Organizations; feedback/invites
  // Group3 = map, visuals, info pages, community list, definitions
  // "Minimal" website = all 3 set to false.
  // ####################
  // ### NOTE: If changing the enableGroupX values please also change the corresponding ones in 'Home.js' and 'About.js'
  // ####################

  // Tech Debt: Add a function, when looged in, to detrmine if a user is member or entity or public (default)
  // Below should be mutually exclusive.
  var isMember = false; // Placeholder: but intent is that this would determine what is included if user is a Member.
  var isEntity = false; // Placeholder: but intent is that this would determine what is included if user is a Entity (Producer).
  var isOrg = false; // Placeholder: but intent is that this would determine what is included if user is a Entity (non-Producer).
  var isPublic = true; // !isMember && !isEntity; // Placeholder: but intent is that this would determine what is included if user is Public.
  //console.log("isMember, isEntity, isPublic", isMember, isEntity, isPublic);

  // #####################################

  //var temp = this.props.routes[this.props.routes.length-1];

  //const history = useHistory(); // DOES NOT WORK
  //const data = { name: 'John', age: 30 };

  /*
    {    
      title: <div>Sustainable<b>+</b> Products</div>,
      url: '/',
    },
    {    
      title: <div>Sustainable<b>+</b> Products</div>,
      url: '/product-search2',
    },
    {    
      title: <div>Sustainable<b>+</b> Products</div>,
      url: '/product-search-loggedin',
    },
  */

  // Use for Correct Route Names
  const routeNames = [
    {    
      title: <div>Sustainable<b>+</b> Products</div>,
      url: '/', 
    },
    {    
      title: 'Home',
      url: '/home-hidden', // '/'
    },
    {    
      title: <div>Sustainable<b>+</b> Products</div>,
      url: '/search-member',
    },
    {    
      title: <div>Sustainable<b>+</b> Products</div>,
      url: '/search-public',
    },
    {    
      title: <div>Sustainable<b>+</b> Community</div>,
      url: '/community-list',
    },
    {
      title: 'Producer Profile', // 
      url: '/profile-entity',
    },
    {
      title: 'Member Account',
      url: '/profile-member-individual',
    },
    {
      //title: 'Non-Producer Profile',
      title: 'Community (Non-Producer) Profile',
      url: '/profile-member-organization',
    },
    {
      title: "Claim Ballot", // "Rating Model"
      url: '/info-member',
    },
    {
      title: "Claim Info", // "Rating Model"
      url: '/info-public',
    },
    {
      title: "Visualizations", // "Semantic Graphs"
      url: '/visuals',
    },
    {
      title: "Distribution Map", // "Map"
      url: '/map',
    },
    {
      title: "Experimental GeoMap", // "Map"
      url: '/geomap',
    },
    {
      title: "Experimental MapBox", // "Map"
      url: '/mapbox',
    },
    /*
    {
      title: "Experimental SandBox", // "Map"
      url: '/sandbox',
    },
    */
    {
      title: "Definitions", 
      url: '/definitions',
    },
    {
      title: "About Us", // 'About Us'
      url: '/about',
    },
    {
      title: "About", // 'About Us'
      url: '/about-plain',
    },
    {
      title: "Blog",
      url: '/blog',
    },
  ];

  const location = useLocation().pathname;

  function getPageName(path) {
    var pageName = "None"

    const found = routeNames.find((x) => x.url === path);
    if (found) {
      pageName = found.title;
    };

    return pageName
  }
  // For AppBar
  const [appPage, setAppPage] = React.useState(<div>Sustainable<b>+</b> Products</div>); // Used to control login/logout button.
  const [anchorElAppBar, setAnchorElAppBar] = React.useState(null);
  const [showAuthentication, setShowAuthentication] = React.useState(false); // Used to control login/logout button. // Default to 'false' to ensure public can use this.

  const handleMenu = (event) => {
    setAnchorElAppBar(event.currentTarget);
  };
  
  const handleCloseMenu = () => {
    setAnchorElAppBar(null);
  };

  const handleClosePage = () => {
    setAnchorElAppBar(null);
  };

  function menuloggedout () {
    return (
      <div>
        <IconButton
        size="large"
        edge="start"
        color="inherit"
        aria-label="menu"
        aria-controls="menu-appbar"
        aria-haspopup="true"
        onClick={handleMenu}
        sx={{ mr: 0 }}
      >
        <MenuIcon />
      </IconButton>
      <Menu
        id="menu-appbar"
        anchorEl={anchorElAppBar}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={Boolean(anchorElAppBar)}
        onClose={handleCloseMenu}
      >
        { enableGroup1 && <MenuItem component={Link} to={"/search-public"} onClick={handleClosePage}>Product Search </MenuItem> }
        {/*{ enableGroup3 && <Divider /> }*/}
        { enableGroup3 && <MenuItem component={Link} to={`/community-list`} onClick={handleClosePage}>Community List</MenuItem> }
        { (enableGroup1 || enableGroup2) && <Divider />}
        {/*{ (enableGroup2 && (isMember || isPublic)) && <MenuItem component={Link} to={"/profile-member-individual"} onClick={handleClosePage}>Member Account </MenuItem> }*/}
        { (enableGroup1 && (isEntity || isPublic)) && <MenuItem component={Link} to={"/profile-entity"} onClick={handleClosePage}>Producer Profile </MenuItem> }
        { (enableGroup2 && (isOrg || isPublic)) && <MenuItem component={Link} to={"/profile-member-organization"} onClick={handleClosePage}>Community Profile </MenuItem> }
        {/*{ (enableGroup2 && (isOrg || isPublic)) && <MenuItem component={Link} to={"/profile-member-organization"} onClick={handleClosePage}>Non-Producer Profile </MenuItem> }*/}
        { enableGroup3 && <Divider /> }
        { enableGroup3 && <MenuItem component={Link} to={`/info-public`} onClick={handleClosePage}>Claim Information</MenuItem> }
        {/* TEMPORARY{ enableGroup3 && <MenuItem component={Link} to={`/definitions`} onClick={handleClosePage}>Definitions</MenuItem> }*/}
       
        { enableGroup3 && <MenuItem component={Link} to={`/visuals`} onClick={handleClosePage}>Visualizations</MenuItem> }
        { enableGroup3 && <MenuItem component={Link} to={`/map`} onClick={handleClosePage}>Distribution Map</MenuItem> }
        <Divider />
        {/*<MenuItem component={Link} to={"/home"} onClick={handleClosePage}>Home </MenuItem> */}
        <MenuItem component={Link} to={`/blog`} onClick={handleClosePage}>Blog</MenuItem>
        <MenuItem component={Link} to={`/about`} onClick={handleClosePage}>About Us</MenuItem>

      </Menu>
    </div>
    )
  }

  function menuloggedin () {
    return (
      <div>
        <IconButton
        size="large"
        edge="start"
        color="inherit"
        aria-label="menu"
        aria-controls="menu-appbar"
        aria-haspopup="true"
        onClick={handleMenu}
        sx={{ mr: 0 }}
      >
        <MenuIcon />
      </IconButton>
      <Menu
        id="menu-appbar"
        anchorEl={anchorElAppBar}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={Boolean(anchorElAppBar)}
        onClose={handleCloseMenu}
      >

        {/*{ enableGroup1 && <MenuItem component={Link} to={"/search-member"} onClick={handleClosePage}>Product Search </MenuItem> }*/}
        { enableGroup1 && <MenuItem component={Link} to={"/search-public"} onClick={handleClosePage}>Product Search </MenuItem> }
        {/*{ enableGroup3 && <Divider /> }*/}
        { enableGroup3 && <MenuItem component={Link} to={`/community-list`} onClick={handleClosePage}>Community List</MenuItem> }
        { (enableGroup1 || enableGroup2) && <Divider />}
        {/*{ (enableGroup2 && (isMember || isPublic)) && <MenuItem component={Link} to={"/profile-member-individual"} onClick={handleClosePage}>Member Account </MenuItem>}*/}
        { (enableGroup1 && (isEntity || isPublic)) && <MenuItem component={Link} to={"/profile-entity"} onClick={handleClosePage}>Producer Profile </MenuItem>}
        { (enableGroup2 && (isOrg || isPublic)) && <MenuItem component={Link} to={"/profile-member-organization"} onClick={handleClosePage}>Community Profile </MenuItem> }
        {/*{ (enableGroup2 && (isOrg || isPublic)) && <MenuItem component={Link} to={"/profile-member-organization"} onClick={handleClosePage}>Non-Producer Profile </MenuItem> }*/}
        { enableGroup3 && <Divider /> }
        {/*{ enableGroup3 && <MenuItem component={Link} to={`/info-member`} onClick={handleClosePage}>Claims Ballot</MenuItem> }*/}
        { enableGroup3 && <MenuItem component={Link} to={`/info-public`} onClick={handleClosePage}>Claim Information</MenuItem> }
        {/* TEMPORARY{ enableGroup3 && <MenuItem component={Link} to={`/definitions`} onClick={handleClosePage}>Definitions</MenuItem> }*/}
        { enableGroup3 && <MenuItem component={Link} to={`/visuals`} onClick={handleClosePage}>Visualizations</MenuItem> }
        { enableGroup3 && <MenuItem component={Link} to={`/map`} onClick={handleClosePage}>Distribution Map</MenuItem> }
        <Divider />
        {/*<MenuItem component={Link} to={"/home"} onClick={handleClosePage}>Home </MenuItem> */}
        <MenuItem component={Link} to={`/blog`} onClick={handleClosePage}>Blog</MenuItem>
        <MenuItem component={Link} to={`/about`} onClick={handleClosePage}>About Us</MenuItem>

      </Menu>
    </div>
    )
  }

  function appbarbasicloggedout() {
    return(
      <>
        {menuloggedout()}
        <Typography variant="h6" component="div" sx={{ flexGrow: 1}}>
          {getPageName(location)}
        </Typography>
        
      </>
    )
  }

  function appbarbasicloggedin() {
    return(
      <>
        {menuloggedin()}
        <Typography variant="h6" component="div" sx={{ flexGrow: 1}}>
          {getPageName(location)}
        </Typography>
      </>
    )
  }

  function myheaderlogin() {
    return (
      <Grid container spacing={0}>
        <Grid item xs={12}>
          <Box sx={{ flexGrow: 1 }}>
            {!appOverride && 
              <AppBar position="static" sx={{backgroundColor: "#009933"}}>
                <Toolbar>
                  {appbarbasicloggedout()}
                  <ButtonMUI component={Link} to={`/`} color="inherit" onClick={() => setShowAuthentication(!showAuthentication)}>Login</ButtonMUI>
                </Toolbar>
              </AppBar>
            }
          </Box>
        </Grid>

      </Grid>
    );
  }

// Maybe make the menu an item inside here (2 variants)

  // Login/Logout Handling
  function myAuthenticatedApp() {
    // Excluded in <Authenticator formFields={formFields} components={components}>; using defaults
    // Note: signUpAttributes should pick up 'email' automatically, but since I had added a blank signUpAttributes={[]} it did not. Now removed.
    // Alt, add explicitly: signUpAttributes={['email']} //  
    if(showAuthentication) {
      return (
        <Authenticator variation="modal" > 
          {({ signOut, user }) => (
            <div>
              <Grid container spacing={0}>
                <Grid item xs={12}>
                  <Box sx={{ flexGrow: 1 }}>
                    <AppBar position="static" sx={{backgroundColor: "#009933"}}>
                      <Toolbar>
                        {appbarbasicloggedin()}
                        <Box sx={{mr:4}}>
                          <IconButton
                            aria-label="account of current user"
                            color="inherit"
                          >
                            <AccountCircle />
                          </IconButton>
                          <Typography variant="overline" fontStyle='oblique'> {user.username}</Typography>
                        </Box>
                        <ButtonMUI color="inherit" onClick={() => {
                          setShowAuthentication(!showAuthentication);
                          signOut();
                        }}>Logout</ButtonMUI>
                      </Toolbar>
                    </AppBar>
                  </Box>
                </Grid>
              </Grid> 
              {myroutes()}
              {/*<Typography>APP LEVEL USER: {user.username}</Typography>*/}
            </div>
          )}
        </Authenticator>
      );
    }
    else {
      return (
        <div>
          {myheaderlogin()}
          {myroutes()}
        </div>
      )
    }
  }

  // For Routes - for normal APP
  function myroutes() {
    return (
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<SearchPublic />} />
          <Route path="home-hidden" element={<Home />} />
          {/*<Route path="search-member" element={<SearchMember />} />*/}
          <Route path="search-public" element={<SearchPublic />} />
          <Route path="community-list" element={<CommunityList />} />
          <Route path="profile-entity" element={<ProfileEntity />} />
          {/*<Route path="profile-farm" element={<ProfileFarm />} />
          <Route path="profile-brand" element={<ProfileBrand />} />*/}
          <Route path="profile-member-individual" element={<ProfileMemberIndividual />} />
          <Route path="profile-member-organization" element={<ProfileMemberOrganization />} />
          {/*<Route path="info-member" element={<InfoMember />} />*/}
          <Route path="info-public" element={<InfoPublic />} />
          <Route path="about" element={<About />} />
          <Route path="visuals" element={<Visuals />} />
          <Route path="map" element={<Map />} />
          <Route path="geomap" element={<GeoMap />} /> {/* This is experimental only */}
          <Route path="mapbox" element={<MapBox />} /> {/* This is experimental only */}
          {/*<Route path="sandbox" element={<SandBox />} /> */} {/* This is experimental only */}
          <Route path="definitions" element={<Definitions />} />
          <Route path="blog" element={<Blog />} />
          <Route path="*" element={<p>Not found!</p>} />
        </Route>
      </Routes>
    )
  }

  // Separate set of routes to support testing with SimplySemantics app (embed).
  function myroutestest() {
    return (
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Dummy />} />
          <Route path="embedaws" element={<EmbedAWS />} />
          <Route path="embedlocal" element={<EmbedLocal />} />
          <Route path="*" element={<p>Not found!</p>} />
        </Route>
      </Routes>
    )
  }

  // Replaced by Dummy App to allow additional test pages.
  function dummyBanner() {
    return (
      <Grid container spacing={0}>
        <Grid item xs={12}>
          <Box sx={{p: 1, mt:4 }} >
            <Typography variant='subtitle1' color='#000000' fontWeight='normal' ml={2} mb={2}><b>Foodscape</b> beta website now offline - sorry for any inconvenience.</Typography>
            <Typography variant='subtitle1' color='#000000' fontWeight='normal' ml={2} mb={2}>Send any questions to <b>info@simply-regenerative.com</b></Typography>
            {/*<Typography mb={2}>Message Here</Typography>*/}
          </Box>
          {myroutestest()}
        </Grid>
      </Grid>
    )
  }

  function dummyApp(){
    return (
      <Grid container spacing={0}>
        {myroutestest()}
      </Grid>
    )
  }

  return (
    <>

    { !appOverride ?
      myAuthenticatedApp()
    : 
      dummyApp()// dummyBanner()
    }
    </>
  );
};

export default App;

// Note: Before adding dummy banner we had a {} around myAuthenticatedApp() :: {myAuthenticatedApp()}

// before used <> and </> instead of <div>

/* Previous before Helmet
    <>
    { !appOverride ?
      myAuthenticatedApp()
    : 
      dummyBanner()
    }
    </>
*/

/* With Helmet

    <HelmetProvider>
    <div>
    { !appOverride ?
      myAuthenticatedApp()
    : 
      dummyBanner()
    }
    <Helmet>
      <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-3641637998124467" crossorigin="anonymous"></script>
    </Helmet>
    </div>
    </HelmetProvider>


*/

