// Imports from React
//import * as React from 'react';
import React, { useState } from "react";
import { Link } from 'react-router-dom';//

// For Google Analytics
// import React, { useEffect } from "react";
import { useEffect } from "react";
//import ReactGA from "react-ga4";

// Imports from MUI
import {Grid, Box, Container, Stack} from '@mui/material'; 
import Typography from '@mui/material/Typography';
import { Link as LinkMUI} from '@mui/material';


const EmbedLocal = () => {

  // ##############################
  // Return / JSX Functions
  // ##############################

  function embedwindow() {

    return(
      <Box sx={{p: 1 }} >
        {/*<Typography variant='subtitle1' mb={1}> 
          Hello <span style={{color: "#009933"}}><b>World</b></span> !!
        </Typography>*/}
        <Box>
          <Typography variant='h5' fontWeight='bold'>Client Website (Regenified)</Typography>
        </Box>
        <Box>
          <Typography>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
          </Typography>
        </Box>
        <Box>
            <iframe 
              src="http://localhost:5173/e/0fad06bc-fcfc-4feb-8b44-99ab649c4c41"
              //src="https://main.d3o045zcx794a.amplifyapp.com/e/0fad06bc-fcfc-4feb-8b44-99ab649c4c41"
              width="100%" // Need to consider min width of widget + delta (20) // 520
              height="270" // Need to consider min heigth of widget + delta
              frameBorder="0" // Listed as deprecated ... not sure what that means here.
              ////scrolling="no"
              //allowFullScreen
              title="Test"
              //sandbox="allow-scripts"
              >
                {/*<p><a href="http://localhost:5173/regenified">Us Directly on ScapeAI</a></p>*/}
              </iframe>
        </Box>
        <Box>
          <Typography>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
          </Typography>
        </Box>
        <Box>
          {/* SAME AS iframe <embed 
              src="http://localhost:5173/e/0fad06bc-fcfc-4feb-8b44-99ab649c4c41"
              width={420} // Need to consider min width of widget + delta
              //height={420} // Need to consider min heigth of widget + delta
              frameBorder={0} // Listed as deprecated ... not sure what that means here.
              ////scrolling="no"
              ////allowFullScreen
              title="Test"
            ></embed>*/}
            {/* FAIL <object 
              src="http://localhost:5173/e/0fad06bc-fcfc-4feb-8b44-99ab649c4c41"
              width={420} // Need to consider min width of widget + delta
              //height={420} // Need to consider min heigth of widget + delta
              frameBorder={0} // Listed as deprecated ... not sure what that means here.
              ////scrolling="no"
              ////allowFullScreen
              title="Test"
            ></object>*/}
          {/* FAIL <link rel="import" href="http://localhost:5173/e/0fad06bc-fcfc-4feb-8b44-99ab649c4c41">Here</link>*/}
          <LinkMUI color="inherit" underline="hover" target="_blank" rel="noopener" href="http://localhost:5173/regenified">Our Page directly on ScapeAI.</LinkMUI>
        </Box>
        {/*<Box>
          <Typography>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
          </Typography>
        </Box>*/}
      </Box>)
  };
          
  return (
    <div>
      <Grid container spacing={0}>
        <Grid item xs={12}>
          {embedwindow()}
        </Grid>
      </Grid>
    </div>
  )
};

export default EmbedLocal;


