// Imports from React
//import * as React from 'react';
import React, { useState } from "react";

// Imports from MUI
import {Grid, Box, Container, Stack} from '@mui/material'; 
import Typography from '@mui/material/Typography';


const Dummy = () => {

  // ##############################
  // Return / JSX Functions
  // ##############################
          
  return (
    <Grid container spacing={0}>
      <Grid item xs={12}>
        <Box sx={{p: 1, mt:4 }} >
          <Typography variant='subtitle1' color='#000000' fontWeight='normal' ml={2} mb={2}><b>Foodscape</b> beta website now offline - sorry for any inconvenience.</Typography>
          <Typography variant='subtitle1' color='#000000' fontWeight='normal' ml={2} mb={2}>Send any questions to <b>info@simply-regenerative.com</b></Typography>
        </Box>
      </Grid>
    </Grid>
  )
};

export default Dummy;


